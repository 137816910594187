(() => {
  window.addEventListener('DOMContentLoaded', event => {

    const operatorHeader3Blocks = document.querySelectorAll('.operator-header-3-wrapper');

    operatorHeader3Blocks.forEach( block => {
      let copyCodeBtn = block.querySelector('.bcb_counter_btn');
      copyCodeBtn.addEventListener('click', function() {
        let copyCodeHref = copyCodeBtn.getAttribute('href');
        window.open(copyCodeHref, '_blank');
      });
    });

  });
})();
